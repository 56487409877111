import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import image from "../images/companyDefault.jpg";
import axios from "axios";
import { Context } from "../App.jsx";

function WorkshopComponent(props) {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };
  const [free, setFree] = useState("FREE");
  // const handleFree = () => {
  //   if (props.fee == 0 && english) {
  //     return setFree("FREE");
  //   }
  // };
  // useEffect(() => {
  //   handleFree();
  // }, [english]);
  return (
    <div className="job-item">
      <Link to="/workshop/details" state={props.workshopID}>
        <div className="flex space-between">
          <div className="text">
            <div className="job-title">{props.eventName}</div>
            <div className="company-name">{props.companyName}</div>
            <div className="location">
              {english ? "District: " : "地區: "}
              {props.district}
            </div>
            <div className="languages">
              {english ? "Language: " : "語言 :"} {props.language}
            </div>
            <div className="date">
              {" "}
              {english ? "Date: " : "日期: "}
              {props.date.slice(5)}
            </div>
            <div className="time">
              {english ? "Time: " : "時間: "} {props.startTime} -{" "}
              {props.endTime}
            </div>
          </div>
          <div className="image">
            <img src={image} alt="" />
          </div>
        </div>
        <div className="flex space-between">
          <div className="tags"></div>
          <div className="wage-container">
            <div className="wage">
              {english ? "Fee: " : "費用: "} {props.fee}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default WorkshopComponent;
