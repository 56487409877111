import React, { useEffect, useState, useContext } from "react";
import image from "./images/logo1.png";
import image2 from "./images/profileDefault.jpg";
import image3 from "./images/banner.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import JobComponent from "./components/JobComponent";
import LanguageComponent from "./components/LanguageComponent.jsx";
import { Context } from "./App.jsx";
import { faBiking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import WorkshopComponent from "./components/WorkshopComponent.jsx";
import Navbar from "./components/Navbar.jsx";

// React - Date - Range
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker, DateRange } from "react-date-range";

function Workshop() {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const navigate = useNavigate();

  const singleSelectCheckboxes = document.querySelectorAll(".single-select");
  // Add click event listener to each checkbox
  singleSelectCheckboxes.forEach((checkbox) => {
    checkbox.addEventListener("click", function () {
      // Uncheck all other checkboxes in the group
      singleSelectCheckboxes.forEach((cb) => {
        if (cb !== this) {
          cb.checked = false;
        }
      });
    });
  });

  // useEffect to get data from backend

  // States
  const [dates, setDates] = useState([]);
  const [dateFilters, setDateFilters] = useState([]); // Date Filter
  const [workshopData, setWorkshopData] = useState([]);
  const [jobID, setJobID] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]); // Day Filter
  const [hourFilters, setHourFilters] = useState([]); // Hour Filter
  const [filteredItems, setFilteredItems] = useState(workshopData); // Filtered Items
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };

  useEffect(() => {
    axios
      .get(apiUrl + "/workshop", {
        headers: { Authorization: `Bearer ${localStorage.token}` },
      })
      .then((res) => {
        console.log(res.data.data.workshops);
        setWorkshopData(res.data.data.workshops);
        // res.data.data.jobs.map((item) =>
        //   setJobID((prev) => [...prev, item._id])
      })
      .catch((err) => {
        console.log(localStorage.token);
        return console.log(err);
      });
  }, []);

  // useEffect to get all the dates
  useEffect(() => {
    const today = new Date();
    const allDates = [];

    for (let i = 0; i < 31; i++) {
      const date = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + i
      );
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}`;
      allDates.push(formattedDate);
    }

    setDates(allDates);
  }, []);

  useEffect(() => {
    workshopData.map((item) => setFilteredItems((prev) => [...prev, item]));
    // jobID.map((item) => setFilteredItems((prev) => [...prev, {['JobID'] : item}]))
  }, [workshopData]);

  // Handle Location Filter Function
  const handleLocation = (e) => {
    if (locationFilter.includes(e.target.value)) {
      document.getElementById("homepage-location").selectedIndex = 0;
      console.log("location exists");
    } else {
      setLocationFilter([...locationFilter, e.target.value]);
      document.getElementById("homepage-location").selectedIndex = 0;
    }
  };

  const handleRemoveLocation = (e) => {
    setLocationFilter([
      ...locationFilter.filter((item) => item != e.target.value),
    ]);
  };

  // Handle Hour Filter Function
  const handleHourFilter = (e) => {
    setFilteredItems(workshopData);
    if (e.target.checked) {
      setHourFilters([e.target.value]);
    } else {
      setHourFilters([]);
    }
  };
  // Function to get dates in range
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate);
    const dates = [];

    while (date <= new Date(endDate)) {
      const formattedDate = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
      dates.push(formattedDate);
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  // Handle Date Filter whenever someone touches the date filter
  const handleDateFilter = (e) => {
    setDateState([e.selection]);

    const selectedDates = getDatesInRange(
      e.selection.startDate,
      e.selection.endDate
    );
    setDateFilters(selectedDates);
    console.log(selectedDates);
  };

  useEffect(() => {
    if (locationFilter.length == 0 && hourFilters.length == 0) {
      setFilteredItems([...workshopData]);
    } else if (locationFilter.length == 0 && hourFilters.length > 0) {
      setFilteredItems([
        ...workshopData.filter((item) => hourFilters.includes(item.date)),
      ]);
    } else if (hourFilters.length == 0 && locationFilter.length > 0) {
      setFilteredItems([
        ...workshopData.filter((item) =>
          locationFilter.includes(item.district)
        ),
      ]);
    } else if (hourFilters.length > 0 && locationFilter.length > 0) {
      setFilteredItems(
        [
          ...workshopData.filter((item) =>
            locationFilter.includes(item.district)
          ),
        ].filter((item) => hourFilters.includes(item.date))
      );
      // setFilteredItems([
      //   ...filteredItems.filter(),
      // ]);
    }
  }, [locationFilter, hourFilters]);

  return (
    <div className="homepage-container">
      <LanguageComponent />
      <Navbar />
      <div className="flex-container">
        <div className="left">
          <div className="find-a-job-container">
            <div className="title header-2">
              {english ? "Find a Workshop" : "尋找工作坊"}
            </div>
            <div className="sub-title header-3">
              {english ? "District" : "地區"}
            </div>
            <div className="location-container">
              {locationFilter.map((item) => (
                <div className="location-item">
                  <label htmlFor={item} onClick={handleRemoveLocation}>
                    {item}
                    <input type="checkbox" id={item} value={item} />
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </label>
                </div>
              ))}
            </div>
            <select
              name="preferred-location"
              id="homepage-location"
              onChange={handleLocation}
            >
              <option value="" selected disabled>
                {english
                  ? "Select your Preferred District"
                  : "選擇您喜歡的工作地區"}
              </option>
              <option value="離島區">{english ? "Islands" : "離島區"}</option>
              <option value="葵青區">
                {" "}
                {english ? "Kwai Tsing" : "葵青區"}
              </option>
              <option value="北區">{english ? "North" : "北區"}</option>
              <option value="西貢區">{english ? "Sai Kung" : "西貢區"}</option>
              <option value="沙田區">{english ? "Sha Tin" : "沙田區"} </option>
              <option value="大埔區">{english ? "Tai Po" : "大埔區"}</option>
              <option value="荃灣區">
                {" "}
                {english ? "Tsuen Wan" : "荃灣區"}
              </option>
              <option value="屯門區">{english ? "Tuen Mun" : "屯門區"}</option>
              <option value="元朗區">
                {" "}
                {english ? "Yuen Long" : "元朗區"}
              </option>
              <option value="九龍城區">
                {english ? "	Kowloon City" : "九龍城區"}
              </option>
              <option value="觀塘區">{english ? "Kwun Tong" : "觀塘區"}</option>
              <option value="深水埗區">
                {english ? "Sham Shui Po" : "深水埗區"}
              </option>
              <option value="黃大仙區">
                {english ? "Wong Tai Sin" : "黃大仙區"}
              </option>
              <option value="油尖旺區">
                {english ? "Yau Tsim Mong" : "油尖旺區"}
              </option>
              <option value="中西區">
                {english ? "Central and Western" : "中西區"}
              </option>
              <option value="東區">{english ? "Eastern" : "東區"}</option>
              <option value="南區">{english ? "Southern" : "南區"}</option>
              <option value="灣仔區">{english ? "Wan Chai" : "灣仔區"}</option>
            </select>
            <div className="sub-title header-3">
              {" "}
              {english ? "Date" : "日子"}
            </div>
            {/* <div className="day-filter">
              <swiper-container
                slides-per-view="5"
                navigation="true"
                style={{
                  "--swiper-navigation-color": "black",
                  "--swiper-pagination-color": "black",
                }}
              >
                {dates.map((date, index) => (
                  <swiper-slide key={index}>
                    <label htmlFor={date}>
                      <input
                        type="checkbox"
                        id={date}
                        value={date}
                        onClick={handleDateFilter}
                      />
                      <span>{date}</span>
                    </label>
                  </swiper-slide>
                ))}
              </swiper-container>
            </div> */}
            <div className="day-filter-draft">
              <DateRange
                editableDateInputs={true}
                onChange={handleDateFilter}
                moveRangeOnFirstSelection={false}
                ranges={dateState}
              />
            </div>
          </div>
          <div className="banner-container">
            <img src={image3} alt="" />
          </div>
        </div>
        <div className="right">
          {filteredItems.map((item, index) => (
            <WorkshopComponent
              key={index}
              district={item.district}
              address={item.address}
              companyName={item.companyName}
              date={item.date}
              description={item.description}
              endTime={item.endTime}
              eventName={item.eventName}
              fee={item.fee}
              language={item.language}
              space={item.space}
              startTime={item.startTime}
              workshopID={item._id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Workshop;
