import React, { useState, useEffect, useContext } from "react";
import { Context } from "./App.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMoneyBill,
  faLayerGroup,
  faLocationDot,
  faCommentsDollar,
  faComment,
  faTrainSubway,
  faCircleExclamation,
  faCommentDots,
  faSeedling,
  faCalendar,
  faCalendarDays,
  faUsers,
  faClock,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import shirt from "./images/shirt.png";
import map from "./images/map.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import workshopIcon from "./images/companyDefault.jpg";
import LanguageComponent from "./components/LanguageComponent.jsx";

function WorkshopDetails(props) {
  const navigate = useNavigate();

  const location = useLocation();
  const workshopID = location.state;
  console.log(location.state);
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const [modal, setmodal] = useState(false);
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [endTime, setEndTime] = useState("");
  const [eventName, setEventName] = useState("");
  const [fee, setFee] = useState("");
  const [language, setLanguage] = useState("");
  const [space, setSpace] = useState("");
  const [startTime, setStartTime] = useState("");
  const [vacancy, setVacancy] = useState("");
  const [applied, setApplied] = useState(false);
  const [userWorkshopArray, setUserWorkshopArray] = useState([]);

  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };

  const handleOverlay = () => {
    setmodal(!modal);
  };

  const applyJobButton = (
    <button className="apply-job-btn" onClick={handleOverlay}>
      {english ? "Apply" : "應徵"}
    </button>
  );

  const appliedJobButton = (
    <button className="apply-job-btn" disabled={applied ? true : false}>
      {english ? "Applied" : "已應徵"}
    </button>
  );

  useEffect(() => {
    axios
      .get(`${apiUrl}/workshop/${workshopID}`, config)
      .then((res) => {
        console.log(res.data.data.workshops);
        const dataPath = res.data.data.workshops;
        setAddress(dataPath.address);
        setCompanyName(dataPath.companyName);
        setDate(dataPath.date);
        setDescription(dataPath.description);
        setEndTime(dataPath.endTime);
        setEventName(dataPath.eventName);
        setFee(dataPath.fee);
        setLanguage(dataPath.language);
        setSpace(dataPath.space);
        setStartTime(dataPath.startTime);
        setVacancy(dataPath.vacancy);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    axios.get(apiUrl + "/user/me", config).then((res) => {
      console.log(res.data.data.workshops);
      res.data.data.workshops.map((item) => {
        console.log(item.workshop._id);
        setUserWorkshopArray((prev) => [...prev, item.workshop._id]);
      });
    });
  }, []);

  useEffect(() => {
    console.log(userWorkshopArray);
    console.log(workshopID);
    if (userWorkshopArray.includes(workshopID)) {
      console.log("Applied");
      setApplied(true);
    } else {
      console.log("not applied");
    }
  }, [userWorkshopArray]);

  // http://localhost:3000/workshop

  // useEffect(() => {
  //   axios.get(`http://localhost:4000/job/${jobId}`, config).then((res) => {
  //     const dataPath = res.data.data.jobs;
  //     console.log(dataPath)
  //     setStartTime(dataPath.startTime);
  //     setEndTime(dataPath.endTime);
  //     setCompanyName(dataPath.companyName);
  //     setJobName(dataPath.jobTitle);
  //     setDescription(dataPath.description);
  //     setPay(dataPath.pay);
  //     setJobLocation(dataPath.location);
  //     setRequirement(dataPath.requirement);
  //   });
  // }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkFree = () => {
    if (fee == 0) {
      return "FREE";
    } else {
      return fee;
    }
  };

  // const applyForJob = () => {
  //   axios
  //     .post(`http://localhost:4000/job/${jobId}/booking`, null, config)
  //     .then((res) => {
  //       console.log(res);
  //       console.log("Successfully posted job");
  //       navigate("/user/profile");
  //     })
  //     .catch((err) => {
  //       console.log(
  //         "Failed to post at",
  //         `http://localhost:4000/job/${jobId}/booking`
  //       );
  //       console.log(config);
  //       console.log(err);
  //       navigate("/");
  //     });
  // };
  const applyForWorkshop = () => {
    axios
      .post(
        `${apiUrl}/workshop/${workshopID}/booking/workshop`,
        null,
        config
      )
      .then((res) => {
        console.log(res);
        console.log("Successfully posted Job");
        navigate("/user/profile/temp");
      })
      .catch((err) => {
        console.log(
          `posted to ${apiUrl}/workshop/${workshopID}/booking/workshop but failed`,
          err
        );
      });
  };

  const applyJobOverlay = (
    <div className="div">
      <div className="overlay" onClick={handleOverlay}></div>
      <div className="white-container">
        <div className="title">確定應徵?</div>
        <img src={workshopIcon} alt="" />
        <div className="body-text">
          {" "}
          {`${companyName}`} - {`${eventName}`}
        </div>

        <div className="body-text">
          {date} | {startTime} - {endTime} | Fee: {fee}
        </div>
        <div className="button-container">
          <button onClick={handleOverlay}>取消</button>
          <button onClick={applyForWorkshop}>確定</button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="workshop-details-container">
      <LanguageComponent />
      {modal ? applyJobOverlay : null}
      <div className="top-container">
        <Link to="/workshop">
          <button>回去</button>
        </Link>
        <div className="job-title h1">
          {" "}
          {english ? "Workshop Details" : "工作坊詳情"}
        </div>
      </div>
      <div className="details-container">
        {/* <div className="left">
          <div className="company-container">
            <div className="image">
              <img src={workshopIcon} alt="" />
            </div>
            <div className="header">{companyName}</div>
            <div className="sub-title">{eventName}</div>
          </div>
          <div className="details-container">
            <div className="header">
              Workshop Details
            </div>
            <div className="body">
              Price: ${fee}
            </div>
            <div className="time">
              Time: {startTime} - {endTime}
            </div>
            <div className="location">
              Location: {address}
            </div>
            <div className="language">
              Language: {language}
            </div>
            <div className="pax">
              Pax: {space}
            </div>
            
          </div>
        </div>
        <div className="right"></div> */}
        <div className="left">
          <div className="job-summary">
            <div className="title h2">
              {companyName} - {eventName}
            </div>
            <div className="summary-items">
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faCommentsDollar} />
                </div>
                <div className="text">
                  <div className="subtitle h3">{english ? "Fee" : "費用"}</div>
                  <div className="pay">{checkFree()}</div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faLanguage} />
                </div>
                <div className="text">
                  <div className="subtitle h3">
                    {english ? "Language" : "語言"}
                  </div>
                  <div className="pay">{language}</div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <div className="text">
                  <div className="subtitle h3">
                    {english ? "Location" : "地點"}
                  </div>
                  <div className="pay">{address}</div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faCalendarDays} />
                </div>
                <div className="text">
                  <div className="subtitle h3">{english ? "Date" : "日期"}</div>
                  <div className="pay">{date}</div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <div className="text">
                  <div className="subtitle h3">{english ? "Time" : "時間"}</div>
                  <div className="pay">
                    {startTime} - {endTime}
                  </div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <div className="text">
                  <div className="subtitle h3">
                    {english ? "Vacancy" : "人數"}
                  </div>
                  <div className="pay">{vacancy}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="job-detail">
            <div className="title h2">
              {english ? "Workshop Details" : "工作坊詳情"}
            </div>
            <div className="detail-text">{description}</div>
          </div>
          {/* <div className="dress-code">
            <div className="h2">著裝要求</div>
            <div className="img-container">
              <swiper-container
                slides-per-view="3"
                navigation="true"
                pagination="true"
                speed="500"
                css-mode="true"
              >
                <swiper-slide>
                  <img src={shirt} alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src={shirt} alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src={shirt} alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src={shirt} alt="" />
                </swiper-slide>
              </swiper-container>
            </div>
          </div> */}
        </div>
        <div className="right">
          {applied ? appliedJobButton : applyJobButton}
        </div>
      </div>
    </div>
  );
}

export default WorkshopDetails;
