
export const EMPLOYER = {
    chinese: {
        typeWorker:'　'+'求職者',
        typeEmployer: '　'+'僱主',
        // const fakeData = [<div>hello <span className="red">red</span> bye</div>, ]
        mainHeader: '是時候以包容的心態打造您的團隊 舉辦工作坊和提供相關資訊便可提升辨識度',
        // mainHeader1: 'It’s time to build your team with', 
        // mainHeader2: 'inclusivity',
        // mainHeader3: 'in mind. Increase',
        // mainHeader4: 'exposure',
        // mainHeader5: ' through workshop offerings and information',
        // mainHeader6: '',        
        mainBody: '按照您的需求招募員工，立即聘請媽媽來支持您的核心團隊。透過提供工作坊和相關資訊就可以為您的公司進行推廣。',
        loginSignup: '登入/加入我們',
        // mainHeader2A: '重新',
        // mainHeader2B: '定義',
        // mainHeader2C: '一個工作平台能提供的一切',
        // upside1: '彈性工作',
        // upside1Content: '提供您彈性、地點方便的工作',
        // upside2: '工作坊',
        // upside2Content: '提供工作坊助您增值自己、更有自信地重返職場',
        // upside3: '培訓',
        // upside3Content: '助您掌握工作市場上的最新資訊',
        mainHeader2A: '',
        mainHeader2B: '3步 ',
        mainHeader2C: '便可輕鬆宣傳您的品牌',
        mainHeader3: '免費發佈您的招聘，任何時間，任何地點',
        mainContent3: '以電郵地址登記，無論身處哪裏都可以管理您的招募信息',
        mainHeader4: '提供公司的品牌曝光度形象建立',
        mainContent4: '宣傳您的品牌同時也可以幫助更多想要重返職場的母親',
        mainHeader5: '通過發佈資訊便可以和您的員工和目標受眾建立聯繫',
        mainContent5: '讓受眾了解您最新的消息同時提供有關他們重返勞動市場的資訊',
    },
    english: {
        typeWorker:'　'+'Worker',
        typeEmployer:'　'+'Employer',
        mainHeader: 'It’s time to build your team with inclusivity in mind. Increase exposure  through workshop offerings and information',
        // mainHeader1: 'It’s time to build your team with', 
        // mainHeader2: 'inclusivity',
        // mainHeader3: 'in mind. Increase',
        // mainHeader4: 'exposure',
        // mainHeader5: ' through workshop offerings and information',
        // mainHeader6: '',
        mainBody: 'Gain access to quality pool of mothers to support your core team and can hire on demand. Promote through providing workshops and relevant information.',
        loginSignup: 'Log in / Sign Up Now',
        mainHeader2A: 'Stay connected with people in need in ',
        mainHeader2B: '3 simple steps ',
        mainHeader3: 'Post your job for free. Anytime. Anywhere.',
        mainContent3: 'Register with an email address, and manage your job posts wherever you are',
        mainHeader4: 'Gain brand exposure and image through workshop offerings',
        mainContent4: 'Promote you brands offering and values while building up mothers confidence in re-entering the workforce',
        mainHeader5: 'Connect with your workers and target audience through your latest news',
        mainContent5: 'Keep your audience up to date on the latest news while providing informative information on their road back to the workforce',
    }
}

export const WORKER = {
  chinese: {
    typeWorker: "　" + "求職者",
    typeEmployer: "　" + "僱主",
    mainHeader: "媽媽無需在家庭和職場之間取捨",
    // mainHeader1: '沒有母親應該在',
    // mainHeader2: '工作',
    // mainHeader3: '與',
    // mainHeader4: '照顧家庭',
    // mainHeader5: '之間作出艱難',
    // mainHeader6: '選擇',
    mainBody:
      "前所未有的方式，按照客戶的需求提供足夠的員工。Flex 可以幫妳持續與提供臨時工作的品牌聯繫起來。讓妳能夠完美兼顧的家庭責任，同時可以參加工作坊和了解最新市場資訊。",
    loginSignup: "登入/加入我們",
    mainHeader2A: "重新",
    mainHeader2B: "定義",
    mainHeader2C: "一個工作平台能提供的一切",
    mainContent1A: "彈性工作",
    mainContent1AContent: "提供靈活且近距離的工作",
    mainContent1B: "工作坊",
    mainContent1BContent: "提供工作坊助您增值自己、更有自信地重返職場",
    mainContent1C: "培訓",
    mainContent1CContent: "助您掌握工作市場上的最新資訊",
    mainHeader3A: "",
    mainHeader3B: "賺錢",
    mainHeader3C: "變得簡單... ",
    mainContent3AImage: "1....",
    mainContent3BImage: "2....",
    mainContent3CImage: "3....",
    mainContent3DImage: "4....",
    mainContent2AContent: "前往我們的網站並建立賬號",
    mainContent2BContent: "⁠輸入妳的優點和經驗",
    mainContent2CContent: "報名彈性工作",
    mainContent2DContent: "到達工作地點並使用我們的程式報到",
    mainHeader4A: "我們提供更加多",
    mainHeader4B: "兼職",
    mainHeader4C: "工作...",
    mainContent4AHeader: "完全彈性",
    mainContent4AContent:
      "任何時間、任何地點，應聘適合妳的工作。我們會提供各式各樣的工作來適配妳的時間。只需申請一次便能立即得到報酬，沒有其他附加條件和限制!",
    mainContent4BHeader: "無需履歷",
    mainContent4BContent:
      "我們認為妳的價值不應拘限於履歷上，努力和奉獻才是每個職位最重要的條件。",
  },
  english: {
    typeWorker: "　" + "Worker",
    typeEmployer: "　" + "Employer",
    mainHeader: "No mother should choose between her kids and her career",
    mainHeader1: "No mother should ",
    mainHeader2: "choose ",
    mainHeader3: "between her ",
    mainHeader4: "kids ",
    mainHeader5: "and her ",
    mainHeader6: "career",
    mainBody:
      "The revolutionary way to earn money on-demand. Flex connects you to brands that offers consistent and flexible jobs. Work seamlessly around your family responsibilities, while having access to workshops and the latest news.",
    loginSignup: "Log in / Sign Up Now",
    mainHeader2A: "We are ",
    mainHeader2B: "redefining ",
    mainHeader2C: "what a job platform should offer",
    mainContent1A: "Flexible Jobs",
    mainContent1AContent: "Flexible and close proximity jobs available",
    mainContent1B: "Workshops",
    mainContent1BContent:
      "Workshops to upskill and gain the confidence to re-enter the workforce",
    mainContent1C: "Lack of Training",
    mainContent1CContent:
      "Get the most up to date information on the job market and other relevant information",
    mainHeader3A: "Earning ",
    mainHeader3B: "money ",
    mainHeader3C: "is as easy as counting from... ",
    mainContent3AImage: "1....",
    mainContent3BImage: "2....",
    mainContent3CImage: "3....",
    mainContent3DImage: "4....",
    mainContent2AContent: "Go to our web platform and create an account",
    mainContent2BContent: "Enter preferences and experiences",
    mainContent2CContent: "???",
    mainContent2DContent: "Arrive and Check In via our app",
    mainHeader4A: "We offer much more than a ",
    mainHeader4B: "part time ",
    mainHeader4C: "job...",
    mainContent4AHeader: "Total Flexibility",
    mainContent4AContent:
      "Work where and when you want. We offer jobs in various length to accommodate your schedule. Apply once and get paid immediately with no strings attached!",
    mainContent4BHeader: "No CV necessary",
    mainContent4BContent:
      "We value you as the greatest asset, hardwork and dedication is the only requirement for all the roles available on the platform",
  },
};