import { register } from "swiper/element/bundle";
import HomePage from "./HomePage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import JobDetails from "./JobDetails";
import SignUpEmployee from "./SignUpEmployee";
import SignUp from "./SignUp";
import SignUpEmployer from "./SignUpEmployer";
import LoginEmployee from "./Login";
import ForgetPassword from "./ForgetPassword";
import UserProfile from "./UserProfile";
import Workshop from "./Workshop";
import WorkshopDetails from "./WorkshopDetails";
import ViewJobDetails from "./ViewJobDetails";
import ViewWorkshopDetails from "./ViewWorkshopDetails";
import LandingApp from "./LandingApp";
import EmployerDetails from "./EmployerDetails";
import EmployerHome from "./EmployerHome";
import CreateJobEmployer from "./CreateJobEmployer";
import JobDetailsEmployer from "./JobDetailsEmployer";
import ViewWorkshopDetailsEmployer from "./ViewWorkshopDetailsEmployer";
import CKTest from "./CKTest";
import TestPage from "./ViewCandidatesEmployer";
import ViewCandidateDetails from "./ViewCandidateDetails";
import EditEmployeeProfile from "./EditEmployeeProfile";
import EditEmployerProfile from "./EditEmployerProfile";

import "./stylesheets/App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { createContext, useState } from "react";
import CreateWorkshopEmployer from "./CreateWorkshopEmployer";
import ViewCandidatesEmployer from "./ViewCandidatesEmployer";

const router = createBrowserRouter([
  { path: "/", element: <LandingApp /> },
  {
    path: "/employee/job",
    element: <HomePage />,
  },
  {
    path: "/jobdetail",
    element: <JobDetails />,
  },
  {
    path: "/user/details",
    element: <SignUpEmployee />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signup/employer",
    element: <SignUpEmployer />,
  },
  {
    path: "/login",
    element: <LoginEmployee />,
  },
  {
    path: "/forget",
    element: <ForgetPassword />,
  },
  { path: "/user/profile/temp", element: <UserProfile /> },
  {
    path: "/workshop",
    element: <Workshop />,
  },
  { path: "/workshop/details", element: <WorkshopDetails /> },
  { path: "/view/jobdetail", element: <ViewJobDetails /> },
  { path: "/view/workshop", element: <ViewWorkshopDetails /> },
  { path: "/employer/details", element: <EmployerDetails /> },
  { path: "/home/employer", element: <EmployerHome /> },
  { path: "/create/job", element: <CreateJobEmployer /> },
  { path: "/create/workshop", element: <CreateWorkshopEmployer /> },
  { path: "/employer/view/jobdetail", element: <JobDetailsEmployer /> },
  { path: "/employer/view/workshop", element: <ViewWorkshopDetailsEmployer /> },
  { path: "/test/ckeditor", element: <CKTest /> },
  { path: "/testpage", element: <TestPage /> },
  { path: "/employer/view/candidates", element: <ViewCandidatesEmployer /> },
  {
    path: "/employer/view/candidates-detail",
    element: <ViewCandidateDetails />,
  },

  {
    path: "/user/edit/profile",
    element: <EditEmployeeProfile />,
  },
  {
    path: "/employer/edit/profile",
    element: <EditEmployerProfile />,
  },
]);

export const Context = createContext();

register();

function App() {
  const [english, setEnglish] = useState(false);
  // const [apiUrl, setApiUrl] = useState("http://localhost:4000");
  const [apiUrl, setApiUrl] = useState(
    "https://flexapp-0c3a60ff7bd0.herokuapp.com"
  );

  return (
    <div className={english ? "App ENG" : "App CHI"}>
      <Context.Provider value={{ english, setEnglish, apiUrl, setApiUrl }}>
        <RouterProvider router={router} />
      </Context.Provider>
    </div>
  );
}

export default App;
