import React from "react";
import { useState, useEffect, useContext } from "react";
import { Context } from "./App.jsx";
import { Link, useLocation } from "react-router-dom";

function WorkshopListingItem(props) {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  return (
    <div className="listing-item">
      <div className="left-side flex">
        <div className="logo-container">
          <img src={props.logo} alt="" />
        </div>
        <div className="flex-column">
          <div className="sub-header">
            {props.companyName} - {props.jobTitle}
          </div>
          <div className="body-text">
            {props.date} | {props.startTime} - {props.endTime} | ${props.pay}/hr
          </div>
        </div>
      </div>

      <div className="middle">
        <div className="sub-header">{english ? "Location" : "地點"}</div>
        <div className="body-text">{props.location}</div>
      </div>

      <div className="right-side flex-column">
        <Link
          to={props.employee ? "/view/workshop" : "/employer/view/workshop"}
          state={props.jobID}
        >
          <button>{english ? "View" : "查看"}</button>
        </Link>
      </div>
    </div>
  );
}

export default WorkshopListingItem;
