import React, { useEffect, useState, useContext } from "react";
import { Context } from "./App.jsx";
import { Link, useNavigate } from "react-router-dom";
import LanguageComponent from "./components/LanguageComponent.jsx";
import image from "./images/logo1.png";
import image2 from "./images/profileDefault.jpg";
import image3 from "./images/banner.png";
import axios from "axios";
import JobComponent from "./components/JobComponent.jsx";
import { faBiking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Navbar from "./components/Navbar.jsx";

// React - Date - Range
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker, DateRange } from "react-date-range";

function HomePage() {
  // States
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  // Fake Data for Testing
  const [fakeDisplay, setFakeDisplay] = useState([]);
  const [fakeJobData, setFakeJobData] = useState([
    { name: "job1", date: "2024-08-02", district: "離島區", category: "清潔" },
    { name: "job2", date: "2024-08-03", district: "離島區", category: "紡織" },
    { name: "job3", date: "2024-08-02", district: "沙田區", category: "紡織" },
    { name: "job3", date: "2024-08-03", district: "沙田區", category: "清潔" },
    { name: "job5", date: "2024-08-04", district: "沙田區", category: "清潔" },
  ]);
  const [dates, setDates] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [jobID, setJobID] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]); // Category Filter
  const [locationFilter, setLocationFilter] = useState([]); // Location Filter
  const [dateFilters, setDateFilters] = useState([]); // Date Filter
  const [filteredItems, setFilteredItems] = useState(jobData); // Filtered Items
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // Used for Translating Districts
  const districtEnglish = [
    "Islands",
    "Kwai Tsing",
    "North",
    "Sai Kung",
    "Sha Tin",
    "Tai Po",
    "Tsuen Wan",
    "Tuen Mun",
    "Yuen Long",
    "Kowloon City",
    "Kwun Tong",
    "Sham Shui Po",
    "Wong Tai Sin",
    "Yau Tsim Mong",
    "Central and Western",
    "Eastern",
    "Southern",
    "Wan Chai",
  ];

  const districtChinese = [
    "離島區",
    "葵青區",
    "北區",
    "西貢區",
    "沙田區",
    "大埔區",
    "荃灣區",
    "屯門區",
    "元朗區",
    "九龍城區",
    "觀塘區",
    "深水埗區",
    "黃大仙區",
    "油尖旺區",
    "中西區",
    "東區",
    "南區",
    "灣仔區",
  ];
  const jobCategoryEng = ["Cleaning", "Logistics", "Food & Beverage", "Retail"];

  const jobCategoryChi = ["清潔", "物流及運輸", "餐飲", "零售"];

  const navigate = useNavigate();
  const toHome = () => {
    navigate("/employee/job");
  };
  function toWorkshop() {
    navigate("/workshop");
  }
  // Single select for checkbox
  function toProfile() {
    navigate("/user/profile/temp");
  }
  const singleSelectCheckboxes = document.querySelectorAll(".single-select");
  // Add click event listener to each checkbox
  singleSelectCheckboxes.forEach((checkbox) => {
    checkbox.addEventListener("click", function () {
      // Uncheck all other checkboxes in the group
      singleSelectCheckboxes.forEach((cb) => {
        if (cb !== this) {
          cb.checked = false;
        }
      });
    });
  });

  // useEffect to get data from backend
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  useEffect(() => {
    axios
      .get(apiUrl + "/job", config)
      .then((res) => {
        console.log("This is config: ", config);
        console.log(res.data.data.jobs);
        setJobData(res.data.data.jobs);
        res.data.data.jobs.map((item) =>
          setJobID((prev) => [...prev, item._id])
        );
        // res.data.data.
        // console.log(res.data.data.jobs[0]._id) <-- ID
      })
      .catch((err) => {
        console.log("This is config: ", config);
        console.log(localStorage.token);
        return console.log(err);
      });
  }, []);

  useEffect(() => {
    jobData.map((item) => setFilteredItems((prev) => [...prev, item]));
  }, [jobData]);

  useEffect(() => {
    axios.get(apiUrl + "/user/me", config).then((res) => {
      // For debugging use
      // console.log(res);
      // const profilePicUrl =
      //   "http://localhost:4000/img/users/" +
      //   res.data.data.users.details[0].userPhoto;
      // localStorage.setItem("profilePic", profilePicUrl);
    });
  }, []);

  // Handle Category
  const handleCategory = (e) => {
    console.log(e.target.value);
    if (categoryFilter.includes(e.target.value)) {
      document.getElementById("category").selectedIndex = 0;
      console.log("category exists");
    } else {
      setCategoryFilter([...categoryFilter, e.target.value]);
      document.getElementById("category").selectedIndex = 0;
    }
  };
  // Handle Remove Category
  const removeCategory = (index) => {
    const updatedCategory = [...categoryFilter];
    updatedCategory.splice(index, 1);
    setCategoryFilter(updatedCategory);
  };
  // Handle Location Filter Function
  const handleLocation = (e) => {
    if (locationFilter.includes(e.target.value)) {
      document.getElementById("homepage-location").selectedIndex = 0;
      console.log("location exists");
    } else {
      setLocationFilter([...locationFilter, e.target.value]);
      document.getElementById("homepage-location").selectedIndex = 0;
    }
  };

  const handleRemoveLocation = (e) => {
    setLocationFilter([
      ...locationFilter.filter((item) => item != e.target.value),
    ]);
  };

  // Function to get dates in range
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate);
    const dates = [];

    while (date <= new Date(endDate)) {
      const formattedDate = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
      dates.push(formattedDate);
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }
  // Handle Date Filter whenever someone touches the date filter
  const handleDateFilter = (e) => {
    setDateState([e.selection]);

    const selectedDates = getDatesInRange(
      e.selection.startDate,
      e.selection.endDate
    );
    setDateFilters(selectedDates);
    // console.log(selectedDates);
  };

  // OLD FILTER -------
  // useEffect(() => {
  //   if (locationFilter.length == 0 && dateFilters.length == 0) {
  //     setFilteredItems(jobData);
  //   } else if (locationFilter.length == 0 && dateFilters.length > 0) {
  //     setFilteredItems([
  //       ...jobData.filter((item) => dateFilters.includes(item.date)),
  //     ]);
  //   } else if (dateFilters.length == 0 && locationFilter.length > 0) {
  //     setFilteredItems([
  //       ...jobData.filter((item) => locationFilter.includes(item.district)),
  //     ]);
  //   } else if (dateFilters.length > 0 && locationFilter.length > 0) {
  //     setFilteredItems(
  //       [
  //         ...jobData.filter((item) => locationFilter.includes(item.district)),
  //       ].filter((item) => dateFilters.includes(item.date))
  //     );
  //     // setFilteredItems([
  //     //   ...filteredItems.filter(),
  //     // ]);
  //   }
  // }, [locationFilter, dateFilters]);

  // UseEffect for FakeData
  // Filter Use Effect Final
  //  WORKS NOW, CAN TRANSFER TO REAL DATA -------
  useEffect(() => {
    let updatedJobData = jobData; // Start with the original job data

    // Apply location filter
    if (locationFilter.length > 0) {
      updatedJobData = updatedJobData.filter((job) =>
        locationFilter.includes(job.district)
      );
    }

    // Apply category filter
    if (categoryFilter.length > 0) {
      updatedJobData = updatedJobData.filter((job) =>
        categoryFilter.includes(job.category)
      );
    }

    // Apply date filter
    if (dateFilters.length > 0) {
      updatedJobData = updatedJobData.filter((job) =>
        dateFilters.includes(job.date)
      );
    }

    // Set the filtered items
    setFilteredItems(updatedJobData);
  }, [locationFilter, categoryFilter, dateFilters, jobData]);

  // Handle Date Select
  const handleDateSelect = (e) => {
    console.log(e);
  };

  return (
    <div className="homepage-container">
      <LanguageComponent />
      <Navbar />
      <div className="flex-container">
        <div className="left">
          <div className="find-a-job-container">
            <div className="title header-2">
              {english ? "Find a Job" : "尋找工作"}
            </div>
            <div className="sub-title header-3">
              {" "}
              {english ? "District" : "地區"}
            </div>
            <div className="location-container">
              {/* {locationFilter.map((item) => (
                <div className="location-item">
                  <label htmlFor={item} onClick={handleRemoveLocation}>
                    {item}
                    <input type="checkbox" id={item} value={item} />
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </label>
                </div>
              ))} */}
              {locationFilter.map((item) => {
                // Find the index of the item in totalRowData
                const index = districtChinese.indexOf(item);
                // Get the English translation using the index
                const displayItem =
                  english && index !== -1 ? districtEnglish[index] : item;

                return (
                  <div className="location-item">
                    <label htmlFor={item} onClick={handleRemoveLocation}>
                      {displayItem}
                      <input type="checkbox" id={item} value={item} />
                      <FontAwesomeIcon icon={faCircleXmark} />
                    </label>
                  </div>
                );
              })}
            </div>

            <select
              name="preferred-location"
              id="homepage-location"
              onChange={handleLocation}
            >
              <option value="" selected disabled>
                {english
                  ? "Select your Preferred District"
                  : "選擇您喜歡的工作地區"}
              </option>
              <option value="離島區">{english ? "Islands" : "離島區"}</option>
              <option value="葵青區">
                {" "}
                {english ? "Kwai Tsing" : "葵青區"}
              </option>
              <option value="北區">{english ? "North" : "北區"}</option>
              <option value="西貢區">{english ? "Sai Kung" : "西貢區"}</option>
              <option value="沙田區">{english ? "Sha Tin" : "沙田區"} </option>
              <option value="大埔區">{english ? "Tai Po" : "大埔區"}</option>
              <option value="荃灣區">
                {" "}
                {english ? "Tsuen Wan" : "荃灣區"}
              </option>
              <option value="屯門區">{english ? "Tuen Mun" : "屯門區"}</option>
              <option value="元朗區">
                {" "}
                {english ? "Yuen Long" : "元朗區"}
              </option>
              <option value="九龍城區">
                {english ? "	Kowloon City" : "九龍城區"}
              </option>
              <option value="觀塘區">{english ? "Kwun Tong" : "觀塘區"}</option>
              <option value="深水埗區">
                {english ? "Sham Shui Po" : "深水埗區"}
              </option>
              <option value="黃大仙區">
                {english ? "Wong Tai Sin" : "黃大仙區"}
              </option>
              <option value="油尖旺區">
                {english ? "Yau Tsim Mong" : "油尖旺區"}
              </option>
              <option value="中西區">
                {english ? "Central and Western" : "中西區"}
              </option>
              <option value="東區">{english ? "Eastern" : "東區"}</option>
              <option value="南區">{english ? "Southern" : "南區"}</option>
              <option value="灣仔區">{english ? "Wan Chai" : "灣仔區"}</option>
            </select>
            <div className="category-filter">
              <div className="sub-title header-3">
                {english ? "Job Category" : "工種"}
              </div>
              <div className="category-items">
                {categoryFilter.map((item, index) => {
                  // Find the index of the item in totalRowData
                  const index2 = jobCategoryChi.indexOf(item);
                  // Get the English translation using the index
                  const displayItem =
                    english && index2 !== -1 ? jobCategoryEng[index2] : item;

                  return (
                    <div
                      className="category-item"
                      key={index}
                      onClick={() => removeCategory(index)}
                    >
                      <div className="text">{displayItem}</div>
                      <div className="icon">
                        {" "}
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </div>
                    </div>
                  );
                })}
              </div>

              <select name="category" id="category" onChange={handleCategory}>
                <option value="" selected disabled>
                  {english
                    ? "Select your Preferred Industry"
                    : "選擇您喜歡的工種"}
                </option>
                <option value="清潔">{english ? "Cleaning" : "清潔"}</option>
                <option value="物流及運輸">
                  {english ? "Logistics" : "物流及運輸"}
                </option>
                <option value="餐飲">
                  {english ? "Food & Beverage" : "餐飲"}
                </option>
                <option value="零售">{english ? "Retail" : "零售"}</option>
              </select>
            </div>
            <div className="sub-title header-3">
              {english ? "Date" : "日子"}
            </div>

            {/* <div className="day-filter">
              <swiper-container
                slides-per-view="5"
                navigation="true"
                style={{
                  "--swiper-navigation-color": "black",
                  "--swiper-pagination-color": "black",
                }}
              >
                {dates.map((date, index) => (
                  <swiper-slide key={index}>
                    <label htmlFor={date}>
                      <input
                        type="checkbox"
                        id={date}
                        value={date}
                        onClick={handleDateFilter}
                      />
                      <span>{date}</span>
                    </label>
                  </swiper-slide>
                ))}
              </swiper-container>
            </div> */}

            <div className="day-filter-draft">
              <DateRange
                editableDateInputs={true}
                onChange={handleDateFilter}
                moveRangeOnFirstSelection={false}
                ranges={dateState}
              />
            </div>
          </div>
          <div className="banner-container">
            <img src={image3} alt="" />
          </div>
        </div>
        <div className="right">
          {filteredItems.map((item, index) => {
            console.log(item.id); // Log item.id here
            return (
              <JobComponent
                key={index}
                district={filteredItems[index].district}
                date={filteredItems[index].date.slice(5)}
                jobTitle={filteredItems[index].jobTitle}
                companyName={
                  item.company && item.company.companyName
                    ? item.company.companyName
                    : "No Company Name"
                }
                location={filteredItems[index].location}
                tags={filteredItems[index].tags}
                startTime={filteredItems[index].startTime}
                endTime={filteredItems[index].endTime}
                pay={filteredItems[index].pay}
                jobID={item.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
